export const skillList = [
  'HTML5',
  'CSS',
  'JavaScript',
  'Node',
  'Mongoose',
  'React',
  'Firebase',
  'HTML Templating',
  'MERN Stack',
];
